.cover {
    transition: all 0.3s ease;
}

.cover:hover {
    background-color: rgb(38, 169, 224);
    transform: scale(1.05);
    color: #fff;
}


.link-href{
  text-decoration: none;
    color: black;
}
.cover.text-center.p-4.shadow-md.hover-effect {
    border: 1px solid;
    border-radius: 90px;
}