/* App.css */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container {
  height: 100vh; /* Full viewport height to center the spinner */
}

.custom-spinner {
  width: 4rem;
  height: 4rem;
  color: #007bff; /* Customize spinner color */
}

