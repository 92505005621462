.para-text {
  width: inherit;
  margin: auto;
}
.text-font-family {
  font-family: serif;
}
button.btn.btn-primary {
  height: 50px;
  font-size: larger;
}
/* .carousel {
  width: fit-content;
} */

.set-image {
  height: 500px;
  width: 400px;
  object-fit: contain;
}
.bg-light-color {
  background: beige;
}
